import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar/navbar";
import Numbers from "components/Numbers/numbers";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import Intro4 from "components/Intro4/intro4";
import AboutUs3 from "components/About-us3/about-us3";
import DarkTheme from "layouts/Dark";
import ServicesShowcasesGrid from "components/Elf/ShowcaseElf/Showcase-grid";
import Verticals from "components/Services/Verticals";
import ShowcaseMasanory from "components/ShowcaseMasonry/ShowcaseMasanory";
import CustomContactForm from "components/Contact-form/custom-contact-form";

import { Link } from "gatsby";
import { navigate } from 'gatsby';



const config = {
  "serviceId":"service_ab_new",
  "templateId":"template_oracle_cw",
  "apiKey":"4YOz-GkKwqKVJ0YtR",
  "formType":"corporate",
  "formSubType":"event",
  "emailMsg":"Lead For Oracle Event",
  "to":"vijai@elfonze.com"
}

// import Services3Elf from "components/Elf/Services3Elf/Services3Elf";
const Homepage5 = ({location}) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const [eventPopupFlag,setEventPopupFlag] = useState(true);
  const [contactFlag,setContactFlag] = useState(false);


  const handleClose = () => {
    setContactFlag(false)
  }

  const openContactDialog = (e) => {
      e.preventDefault();
      setContactFlag(true);
  }

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  React.useEffect(() => {
    window.addEventListener('scroll',() => {
      if (window.scrollY > 200) {
        setEventPopupFlag(false)
      }
      else if(window.screenY === 0)
        setEventPopupFlag(true)
    })

   
  }, [])

  React.useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('contact-elfonze-at-oracle-cloud-world')) {
      setContactFlag(true);
    }

  }, [location.search])

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      {
        contactFlag
        &&
      //   <div className="position-fixed landingpage-dialog w-75 container">
      //   <section className="row justify-content-center">
      //     <section className="col-lg-8 col-md-8  p-0 position-relative">
      //       <img src="/img/blogs/Blog_10/DM-04.jpg" className="w-100" alt="loader image" srcset="" onClick={redirectToContact}/>
      //       <button className="position-absolute" onClick={handleClose}><span className="pe-7s-close"></span></button>
      //     </section>
      //   </section>
      // </div>
      <div className="landing-page-contact-form">
        <CustomContactForm config={config}/>
        <button className="position-absolute close-btn" onClick={handleClose}><span className="pe-7s-close"></span></button>
      </div>
      }
      <Intro4/>
      <AboutUs3 />
      {/* <Services3 /> */}
      {/* { !!localStorage.getItem('oldServices') ?
        <Services3 /> : <ServicesShowcasesGrid />
      } */}
      {/* <Services3Elf  contentdata={AboutUs}/> */}
      {/* <ServicesShowcasesGrid /> */}
      <Verticals />
      <Numbers />
      <ShowcaseMasanory/>
      <CallToAction />
      {
        (eventPopupFlag && !contactFlag) &&
        <div  className="position-fixed event-bottom-cont">
        <section >
        <Link to="/#" onClick={(e) => openContactDialog(e)}>Come Visit us at Booth #83,<br/> Oracle Cloud World 2024 from <br/>September 9th to 12th<br/> Las Vegas, US.<br/> To get in touch and know more, Click Here!!!</Link>
        </section>
      </div>
      }
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies</title>
      <meta
        key="description"
        name="description"
        content="Elfonze Technologies is a premier IT Services and Consulting, Product Development Engineering, ESDM , Supply Chain Services & Staff Augmentation & Training firm"
      />
    </>
  );
};

export default Homepage5;
